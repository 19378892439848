<template>
	<div class="main-wrapper">
        <transition name="menu-popover">
      <div v-if="lightboxVideo" class="lightbox" @click="closeLightbox()">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div class="lightbox-vid" v-html="lightboxVideo"></div>
        <!-- <img :src="lightboxImage" @click="closeLightbox()"/> -->
      </div>
    </transition>
            

            <section
      class="pt-5 pb-5 bg8 bs-shadow mobile-top"
      
    >
      <div class="container">
        <h1 style="color: #fff;">Training Essentials</h1>
        <p class="text-white lh1">A catalog of suggested instructional videos relevant to the Spacesaver Group.</p>
  
      </div>
    </section>
    <section>

        <div class="container">
            
            
        <div class="row">
            <!--
          <div
            class="col-lg-4 col-12"
          >
          
          <div class="card-block bs-shadow" style="margin-top: 15px;">
            <div class="d-flex droplink">
                <h5 data-v-7aa92b12="" id="st-step-3" style="margin-bottom: 0px;">Topics</h5>
                <span data-v-7aa92b12="" class="material-icons mlauto active-tri"> filter_list </span>
            </div>
            <ul class="flexwrap mt10">
                    <li data-v-7aa92b12="" class="mktBtns"><button data-v-7aa92b12="" class="btn-primary"> Product </button></li>
                    <li data-v-7aa92b12="" class="mktBtns"><button data-v-7aa92b12="" class="btn-primary"> Sales </button></li>
                    <li data-v-7aa92b12="" class="mktBtns"><button data-v-7aa92b12="" class="btn-primary"> Sustainability </button></li>
                    <li data-v-7aa92b12="" class="mktBtns"><button data-v-7aa92b12="" class="btn-primary"> Configura </button></li>
                </ul>
            </div>
        </div>
        -->
        <div class="col-xs-12 col-sm-12 col-12 col-lg-12">


            <div class="card-block bs-shadow" style="margin-top: 15px; padding-top: 15px;">
                <div class="d-flex number-showing" style="margin-bottom: 20px; color: #fff;">
                <router-link
                  :to="{ path: $store.state.route.from.fullPath }"
                  class="mb0 lh1 mrauto list-btn dib d-flex center"
                  style="color: #fff;"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                  Back
                </router-link>
            </div>
            <!--
            <div v-for="topic in TrainingEssentialsData.categories">

                <p>{{topic}}</p>

            </div>
            -->
<!-- Gallery -->
<div class="gallery-container section-container">
    <div class="container">
        <div class="row">
            <div class="col gallery section-description wow fadeIn">
           
                <div class="divider-1 wow fadeInUp"><span></span></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- First row of images -->
                <!--
                <div class="row">
                    <div class="col-md-4 gallery-box wow fadeInDown">
                        <div data-toggle="modal" data-target="#myModal">
                            <img class="w-100" src="/training-images/Rails-Training.jpg" alt="Image 1">
                        </div>
                        <p>Product Training - Rails</p>
                    </div>
                    <div class="col-md-4 gallery-box wow fadeInUp">
                        <div data-toggle="modal" data-target="#myModal">
                            <img class="w-100" src="/training-images/Rails-Training.jpg" alt="Video 1" data-target="#myCarousel" data-slide-to="1">
                        </div>
                        <p>Product Training - Guides</p>
                    </div>
                    <div class="col-md-4 gallery-box wow fadeInDown">
                        <div data-toggle="modal" data-target="#myModal">
                            <img class="w-100" src="/training-images/Rails-Training.jpg" alt="Image 2" data-target="#myCarousel" data-slide-to="2">
                        </div>
                        <p>Product Training - Floor and Ramp</p>
                    </div>

                    <div class="col-md-4 gallery-box wow fadeInDown">
                        <div data-toggle="modal" data-target="#myModal">
                            <img class="w-100 border border-light rounded" src="/training-images/Rails-Training.jpg" alt="Image 1">
                        </div>
                        <p>Product Training - Rails</p>
                    </div>
                    <div class="col-md-4 gallery-box wow fadeInUp">
                        <div data-toggle="modal" data-target="#myModal">
                            <img class="w-100" src="/training-images/Rails-Training.jpg" alt="Video 1" data-target="#myCarousel" data-slide-to="1">
                        </div>
                        <p>Product Training - Guides</p>
                    </div>
                    <div class="col-md-4 gallery-box wow fadeInDown">
                        <div data-toggle="modal" data-target="#myModal">
                            <img class="w-100" src="/training-images/Rails-Training.jpg" alt="Image 2" data-target="#myCarousel" data-slide-to="2">
                        </div>
                        <p>Product Training - Floor and Ramp</p>
                    </div>
                </div>
                -->
                <!-- stop -->

          
                <ul>
                    <li v-for="(item, index) in data" :key="index" style="list-style-type: none;">
                        <h5 >{{ item.name }} </h5>
                        <ul v-if="item.items.length" style="margin-bottom: 20px">
                            <li v-for="(item, index) in item.items" :key="index">
                                <a class="link" target="_blank" v-on:click="
                            showLightboxVideo(item.embed)
                          ">{{ item.title }}</a> </li> 
                        </ul>
                        <hr/>
                    </li>
                </ul>
           
            </div>
        </div>
    </div>
</div>
            </div>



        </div>
    </div>
</div>
    </section>
	</div>
</template>

<script>
import axios from "axios";
import TrainingEssentialsData from "../data/training-essentials-data.json"

export default {
	components: {},
	name: "TrainingEssentials",
	data() {
		return {
			ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
            data: TrainingEssentialsData.categories,
            lightboxVideo: "",
		};
	},
	methods: {
		firstLoad: function() {
			//this.loading = true;
			this.$store.commit("setActivePage", "order-manage");
        },
        showLightboxVideo(media) {
            let file = media;
                //this.$store.commit("UPDATE_LIGHTBOX_VIDEO_FIELD_SERVICE", media);
            this.lightboxVideo = media;
        },
        closeLightbox() {
            this.lightboxVideo = "";
        },
			
		
	},
	computed: {
		
	},
	created() {
		this.$store.commit("showNavigation");

		// console.log(this.$route.path);
	},
	mounted() {
		this.firstLoad();
	},
};
</script>
<style scoped>
.link {
    color: #00b4b4;
    cursor: pointer;
}

.bgwhite {
	background: #fff;
}
.faq-heading {
	margin-bottom: 0 !important;
}
.collapse {
	padding: 0px 10px !important;
}
.collapse.show {
	padding: 0px 10px !important;
}
.faq-01 .btn {
	text-decoration: none !important;
}
.faq-01 .btn:hover {
	text-decoration: none !important;
}

.btn-link {
	color: #333;
}

.btn-link i {
	color: rgba(92, 109, 126, 0.9);
}

.btn-link:hover {
	color: #000;
	text-decoration: none !important;
	background-color: transparent;
	border-color: transparent;
}

.btn-link:focus {
	color: #000;
	text-decoration: none !important;
}

.accordion h5 {
	margin-bottom: 0 !important;
	font-weight: bold !important;
	font-size: 1.1em !important;
	line-height: 1.2;
}

.accordion div {
	padding: 10px;
}

</style>